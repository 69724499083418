<template>
  <div class="tw-text-base tw-text-center tw-py-6">
    <TextNormal>By {{title}} in you agree to the</TextNormal>
    <TextNormal> <a>Terms of Service</a> & <a>Privacy Policy</a></TextNormal>
  </div>
</template>

<script>
import TextNormal from '@/components/atoms/TextNormal'

export default {
  components: {
    TextNormal,
  },
  props: ['title'],

}
</script>

<style>

</style>